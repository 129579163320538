import { request } from './init';
import * as Questionnaire from './questionnaire';
import * as Reserve from './reserve';
import * as GrowthTool from './growthTool';
import * as Cosen from './cosen';
import * as Client from './client';
import * as MemberCentre from './memberCentre';
import * as RewardCard from './rewardCard';
import * as Lottery from './lottery';

function getImage(id) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `/liff/image/${id}`,
      method: 'get',
    };
    request(params).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getShareMessage({
  botId,
  clientId,
  intentId,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/shareMessage',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
        intentId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getImage,
  getShareMessage,
  Questionnaire,
  Reserve,
  GrowthTool,
  Cosen,
  Client,
  MemberCentre,
  RewardCard,
  Lottery,
};
