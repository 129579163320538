import { request } from '../init';

function getQuestionnaire(id) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `liff/questionnaire/${id}`,
      method: 'get',
    };
    request(params).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function submitQuestionnaireAnswer({
  botId,
  questionnaireId,
  clientId,
  answers,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/questionnaireAnswer',
      method: 'post',
    };
    request(params, {
      botId,
      questionnaireId,
      clientId,
      answers,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getQuestionnairePersonalData({ botId, clientId }) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: 'liff/client/questionnaire/',
      method: 'get',
    };
    request(params, {
      params: {
        botId, clientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getQuestionnaire,
  submitQuestionnaireAnswer,
  getQuestionnairePersonalData,
};
