import { request } from '../init';

function getRewardCard({
  botId,
  clientId,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/rewardCard',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getRewardCardGift({
  botId,
  clientId,
  id,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `/liff/rewardCard/gift/${id}`,
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function exchangeRewardCardGift({
  botId,
  clientId,
  name,
  birthday,
  phone,
  email,
  address,
  giftId,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/rewardCard/exchange',
      method: 'post',
      config: { params: { botId, clientId } },
    };
    request(params, {
      name,
      birthday,
      phone,
      email,
      address,
      giftId,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function scanRewardCardPointQRCode({
  botId,
  clientId,
  pointId,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/rewardPoint',
      method: 'post',
      config: { params: { botId, clientId } },
    };
    request(params, {
      pointId,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getRewardCard,
  getRewardCardGift,
  exchangeRewardCardGift,
  scanRewardCardPointQRCode,
};
