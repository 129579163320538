/* eslint-disable no-shadow */
import * as types from '../mutations_type';

// state
const state = {
  questionnaireAnswersListPagination: {
    totalPages: 0,
    currentPage: 0,
    hasPre: false,
    hasNext: false,
  },
  questionnaireAnswersListPage: 1,
  questionnaireAnswersList: [],
  reservationsListPagination: {
    totalPages: 0,
    currentPage: 0,
    hasPre: false,
    hasNext: false,
  },
  reservationsListPage: 1,
  reservationsList: [],
  perpage: 10,
  rewardCardStyle: 0,
};

// mutations
const mutations = {
  [types.QUESTIONNAIRE_ANSWERS_LIST_PAGINATION](state, data) {
    state.questionnaireAnswersListPagination = data;
  },
  [types.QUESTIONNAIRE_ANSWERS_LIST_PAGE](state, data) {
    state.questionnaireAnswersListPage = data;
  },
  [types.QUESTIONNAIRE_ANSWERS_LIST](state, data) {
    state.questionnaireAnswersList = data;
  },
  [types.RESERVATIONS_LIST_PAGINATION](state, data) {
    state.reservationsListPagination = data;
  },
  [types.RESERVATIONS_LIST_PAGE](state, data) {
    state.reservationsListPage = data;
  },
  [types.RESERVATIONS_LIST](state, data) {
    state.reservationsList = data;
  },
  [types.REWARD_CARD_STYLE](state, data) {
    state.rewardCardStyle = data;
  },
};

export {
  state,
  mutations,
};
