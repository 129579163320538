import { request } from '../init';

function getReserve(id) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `liff/tourManagement/${id}`,
      method: 'get',
    };
    request(params).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function submitReservation({
  mantaBotId,
  mantaTourManagementId,
  phone,
  timeStart,
  timeEnd,
  forms,
  clientId,
  orderUserName,
  platform,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/userReservation',
      method: 'post',
    };
    request(params, {
      mantaBotId,
      mantaTourManagementId,
      phone,
      timeStart,
      timeEnd,
      forms,
      clientId,
      orderUserName,
      platform,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getReserveFullLimitPeriod({ id, year, month }) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `liff/tourManagement/limitPeriod/${id}`,
      method: 'get',
    };
    request(params, {
      params: {
        year, month,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getReserve,
  submitReservation,
  getReserveFullLimitPeriod,
};
