import axios from 'axios';
import { request } from '../init';

function getQuestionnaireAnswersList({
  botId,
  clientId,
  page,
  perpage,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/questionnaireAnswers',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
        page,
        perpage,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getQuestionnaireAnswer({ botId, id }) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `/liff/questionnaireAnswer/${id}`,
      method: 'get',
    };
    request(params, {
      params: {
        botId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getReservationList({
  botId,
  clientId,
  type,
  page,
  perpage,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/reservations',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
        type,
        page,
        perpage,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getReservation({ botId, id }) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `/liff/reservation/${id}`,
      method: 'get',
    };
    request(params, {
      params: {
        botId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function deleteReservation({ botId, id }) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `/liff/reservation/${id}`,
      method: 'delete',
    };
    request(params, {
      params: {
        botId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

let getCouponsSource = axios.CancelToken.source();

function getCoupons({
  botId,
  clientId,
  page,
  perpage,
  type,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/coupons',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
        page,
        perpage,
        type,
      },
      cancelToken: getCouponsSource.token,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function cancelGetCouponsSource() {
  getCouponsSource.cancel();
  getCouponsSource = axios.CancelToken.source();
}

function useCoupon({
  botId,
  clientId,
  id,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `/liff/coupons/used/${id}`,
      method: 'put',
      config: { params: { botId, clientId } },
    };
    request(params).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getClientRecords({
  botId,
  clientId,
  page,
  perpage,
  type,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/lottery/client/records',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
        page,
        perpage,
        type,
      },
      cancelToken: getCouponsSource.token,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getQuestionnaireAnswersList,
  getQuestionnaireAnswer,
  getReservationList,
  getReservation,
  deleteReservation,
  getCoupons,
  cancelGetCouponsSource,
  useCoupon,
  getClientRecords,
};
