import apiModules from '@/api/index';
import * as types from '../mutations_type';
import router from '../../router';

const getClient = (context) => {
  const botId = router.currentRoute.value.query?.botId;
  const clientId = context.state?.clientId;
  return apiModules.Client.default.getClient({ botId, clientId }).then((result) => {
    const {
      clientName,
      clientHeadshot,
      authPhone,
      isAuth,
      questionnaireAddress,
      quetionnaireBirthday,
      quetionnaireEmail,
      quetionnaireName,
      quetionnairePhone,
    } = result.data.client;
    const {
      coupons,
      points,
      tickets,
    } = result.data;
    context.commit(types.CLIENT_INFO, {
      name: clientName,
      headshot: clientHeadshot,
      authPhone,
      isAuth,
      questionnaireAddress,
      quetionnaireBirthday,
      quetionnaireEmail,
      quetionnaireName,
      quetionnairePhone,
      coupons,
      points,
      tickets,
      hasActiveGame: result.data?.hasActiveGame,
      hasRewardCard: result.data?.hasRewardCard,
    });
    return result.data;
  }).catch((error) => error.response.data);
};

const updateClientId = (context, payload) => {
  context.commit(types.CLIENT_ID, payload);
};

const updateClientInfo = (context, payload) => {
  context.commit(types.CLIENT_INFO, payload);
};

const sendAuthMessage = (context, {
  phone,
  isResend,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  const clientId = context.state?.clientId;
  return apiModules.Client.default.sendAuthMessage({
    botId,
    phone,
    clientId,
    isResend,
  }).then((result) => result.data).catch((error) => {
    if (error.response.data?.errResponse?.code === 240000) {
      context.dispatch('alert/openDialog', {
        message: '電話格式錯誤',
        hideCancel: true,
        callback: () => {},
      }, { root: true });
    }
    if (error.response.data?.errResponse?.code === 240001) {
      context.dispatch('alert/openDialog', {
        message: '簡訊傳送失敗',
        hideCancel: true,
        callback: () => {},
      }, { root: true });
    }
    return error.response.data;
  });
};

const checkAuthMessage = (context, {
  hashCode,
  otp,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  const clientId = context.state?.clientId;
  return apiModules.Client.default.checkAuthMessage({
    botId,
    clientId,
    hashCode,
    otp,
  }).then((result) => result.data).catch((error) => error.response.data);
};

const unbindAuth = (context) => {
  const botId = router.currentRoute.value.query?.botId;
  const clientId = context.state?.clientId;
  return apiModules.Client.default.unbindAuth({
    botId,
    clientId,
  }).then((result) => result.data).catch((error) => error.response.data);
};

export {
  getClient,
  updateClientId,
  updateClientInfo,
  sendAuthMessage,
  checkAuthMessage,
  unbindAuth,
};
