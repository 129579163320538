/* eslint-disable no-shadow */
import * as types from '../mutations_type';

// state
const state = {
  lotteryGame: {
    title: '',
    description: '',
    gifts: [],
    tickets: 0,
  },
};

// mutations
const mutations = {
  [types.LOTTERY_GAME](state, data) {
    state.lotteryGame = data;
  },
};

export {
  state,
  mutations,
};
