import { request } from '../init';

function getGrowthTool(id) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `/liff/growth-tool/${id}`,
      method: 'get',
    };
    request(params).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function appendAutoTag({
  growthToolId,
  clientId,
  clientName,
  clientHeadshot,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/growth-tool-mapping',
      method: 'post',
    };
    request(params, {
      growthToolId,
      clientId,
      clientName,
      clientHeadshot,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function verifyLineToken(token) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: 'https://api.line.me/oauth2/v2.1/verify',
      method: 'get',
    };
    request(params, {
      token,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getGrowthTool,
  appendAutoTag,
  verifyLineToken,
};
