const minLength = (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length < limit) {
    return `必須選取大於 ${limit} 個選項`;
  }
  return true;
};

const maxLength = (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length > limit) {
    return `必須選取小於 ${limit} 個選項`;
  }
  return true;
};

const equalLength = (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length !== limit) {
    return `必須選取剛好 ${limit} 個選項`;
  }
  return true;
};

export default {
  minLength,
  maxLength,
  equalLength,
};
