import apiModules from '@/api/index';

const getGrowthTool = (context, id) => (
  apiModules.GrowthTool.default.getGrowthTool(id).then((result) => result.data)
);

const appendAutoTag = (context, {
  growthToolId,
  clientId,
  clientName,
  clientHeadshot,
}) => (
  apiModules.GrowthTool.default.appendAutoTag({
    growthToolId,
    clientId,
    clientName,
    clientHeadshot,
  }).then((result) => result.data)
);

const verifyLineToken = (context, token) => (
  apiModules.GrowthTool.default.verifyLineToken({
    token,
  }).then((result) => result.data)
);

export {
  getGrowthTool,
  appendAutoTag,
  verifyLineToken,
};
