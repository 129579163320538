/* eslint-disable no-shadow */
import * as types from '../mutations_type';

// state
const state = {
  currentPoint: 0,
  giftInfo: {
    id: '',
    isDefault: false,
    name: '',
    isLimit: false,
    startTime: null,
    endTime: null,
    description: '',
    points: 999,
    image: {
      id: '',
      imageUrl: '',
      cropUrl: '',
      cropArea: {},
      coordinates: {},
    },
    type: '',
  },
  winningRecordInfoData: {
    id: '',
    mantaLotteryGameId: '',
    mantaRewardsCardId: '',
    clientName: '',
    clientBirthday: '',
    clientPhone: '',
    clientEmail: '',
    clientAddress: '',
    createdAt: '',
    gift: {
      name: '',
      imageId: '',
      image: {},
    },
  },
};

// mutations
const mutations = {
  [types.CURRENT_POINT](state, data) {
    state.currentPoint = data;
  },
  [types.GIFT_INFO](state, data) {
    state.giftInfo = data;
  },
  [types.WINNING_RECORD_INFO_DATA](state, data) {
    state.winningRecordInfoData = data;
  },
};

export {
  state,
  mutations,
};
