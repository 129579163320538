import apiModules from '@/api/index';
import router from '../../router';
import * as types from '../mutations_type';

const getLotteryGame = (context, clientId) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.Lottery.default.getLotteryGame({
    botId,
    clientId,
  }).then((result) => {
    context.commit(types.LOTTERY_GAME, {
      ...result.data.lotteryGame,
      tickets: result.data.tickets,
    });
    return result.data;
  }).catch((error) => error.response.data);
};

const getLotteryGameGifts = (_context, clientId) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.Lottery.default.getLotteryGameGifts({
    botId,
    clientId,
  }).then((result) => result.data)
    .catch((error) => error.response.data);
};

const getLotteryGameClientGifts = (_context, {
  clientId,
  perpage,
  page,
  type,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.Lottery.default.getLotteryGameClientGifts({
    botId,
    clientId,
    perpage,
    page,
    type,
  }).then((result) => result?.data)
    .catch((error) => error?.response?.data);
};

const cancelGetLotteryGameClientGifts = () => {
  apiModules.Lottery.default.cancelGetLotteryGameClientGifts();
};

const submitClientInfoToLotteryGift = (_context, {
  id,
  clientId,
  name,
  birthday,
  phone,
  email,
  address,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.Lottery.default.submitClientInfoToLotteryGift({
    id,
    botId,
    clientId,
    name,
    birthday,
    phone,
    email,
    address,
  }).then((result) => result.data)
    .catch((error) => error.response.data);
};

const playLotteryWheel = (_context, clientId) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.Lottery.default.playLotteryWheel({
    botId,
    clientId,
  }).then((result) => result.data)
    .catch((error) => error.response.data);
};

export {
  getLotteryGame,
  getLotteryGameGifts,
  getLotteryGameClientGifts,
  cancelGetLotteryGameClientGifts,
  submitClientInfoToLotteryGift,
  playLotteryWheel,
};
