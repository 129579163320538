import moment from 'moment';

export const fbSDKInit = (d, s, id) => {
  let js = null;
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) { return; }
  js = d.createElement(s); js.id = id;
  js.src = '//connect.facebook.net/en_US/messenger.Extensions.js';
  fjs.parentNode.insertBefore(js, fjs);
};

export const getParameterByName = (name, url) => {
  const regex = new RegExp(`[?&#]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results || !results[2]) return null;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const formatDate = (date) => {
  if (!moment(date).isValid()) return '';
  moment.locale('zh-tw');
  let dateNow = new Date();
  dateNow = moment(dateNow).format('YYYY/MM/DD');
  const yesterday = moment(date).format('YYYY/MM/DD');
  let strDayName = '';
  const lessDay = moment(dateNow, 'YYYY-MM-DD').diff(moment(yesterday, 'YYYY-MM-DD'), 'days');
  switch (lessDay) {
    case 0: strDayName = '今天'; break;
    case 1: strDayName = '昨天'; break;
    case 2: strDayName = '前天'; break;
    default: strDayName = moment(date).format('YYYY/MM/DD (dd)'); break;
  }
  return strDayName;
};

export const filterDuplicates = (data, according) => {
  const set = new Set();
  const result = data.filter((item) => (!set.has(item[according]) ? set.add(item[according]) : false));
  return result;
};

export const sort = (data, according, ascending) => data.sort((a, b) => {
  if (a[according] === null) return 1;
  if (b[according] === null) return -1;
  if (ascending) return a[according] > b[according] ? 1 : -1;
  return a[according] < b[according] ? 1 : -1;
});
