/* eslint-disable import/prefer-default-export */
import apiModules from '@/api/index';

const getClientId = (_context, { botId, lineClientId }) => (
  apiModules.Cosen.default.getClientId(botId, lineClientId).then((result) => result.data).catch((error) => error.response.data)
);

export {
  getClientId,
};
