/* eslint-disable no-shadow */
import * as types from '../mutations_type';

// state
const state = {
  clientId: '',
  clientInfo: {
    name: '',
    headshot: '',
    authPhone: '',
    isAuth: false,
    questionnaireAddress: '',
    quetionnaireBirthday: '',
    quetionnaireEmail: '',
    quetionnaireName: '',
    quetionnairePhone: '',
    coupons: 0,
    points: 0,
    tickets: 0,
    hasActiveGame: false,
    hasRewardCard: false,
  },
};

// mutations
const mutations = {
  [types.CLIENT_ID](state, data) {
    state.clientId = data;
  },
  [types.CLIENT_INFO](state, data) {
    state.clientInfo = data;
  },
};

export {
  state,
  mutations,
};
