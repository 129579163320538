/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import * as types from '../mutations_type';

// state
const state = {
  dialog: {
    message: '',
    showDialog: false,
    cancelText: '取消',
    hideCancel: false,
    okText: '確定',
    icName: 'ic_manta_alert',
    classOk: 'btn--outline--primaryDark',
    determine: () => {},
    cancel: () => {},
  },
};

// mutations
const mutations = {
  [types.DIALOG](state, data) {
    state.dialog = data;
  },
};

export {
  state,
  mutations,
};
