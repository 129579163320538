import moment from 'moment';
import apiModules from '@/api/index';
import { filterDuplicates } from '@/helpers/util';
import * as types from '../mutations_type';
import router from '../../router';

const getQuestionnaireAnswersList = (context) => {
  const botId = router.currentRoute.value.query?.botId;
  const clientId = context.rootState.client?.clientId;
  const page = context.state.questionnaireAnswersListPage;
  const perpage = context.state?.perpage;
  return apiModules.MemberCentre.default.getQuestionnaireAnswersList({
    botId,
    clientId,
    page,
    perpage,
  }).then((result) => {
    context.commit(types.QUESTIONNAIRE_ANSWERS_LIST, result.data.questionnaireAnswers);
    context.commit(types.QUESTIONNAIRE_ANSWERS_LIST_PAGINATION, result.data.pagination);
    return result.data;
  }).catch((error) => error.response.data);
};

const getNextPageQuestionnaireAnswersList = (context) => {
  const botId = router.currentRoute.value.query?.botId;
  const clientId = context.rootState.client?.clientId;
  const page = context.state.questionnaireAnswersListPage + 1;
  const perpage = context.state?.perpage;
  context.commit(types.QUESTIONNAIRE_ANSWERS_LIST_PAGE, page);
  return apiModules.MemberCentre.default.getQuestionnaireAnswersList({
    botId,
    clientId,
    page,
    perpage,
  }).then((result) => {
    let questionnaireAnswers = [...context.state.questionnaireAnswersList, ...result.data.questionnaireAnswers];
    questionnaireAnswers = filterDuplicates(questionnaireAnswers, 'id');
    context.commit(types.QUESTIONNAIRE_ANSWERS_LIST, questionnaireAnswers);
    context.commit(types.QUESTIONNAIRE_ANSWERS_LIST_PAGINATION, result.data.pagination);
    return result.data;
  }).catch((error) => error.response.data);
};

const getQuestionnaireAnswer = (_context, id) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.MemberCentre.default.getQuestionnaireAnswer({ botId, id })
    .then((result) => result.data)
    .catch((error) => error.response.data);
};

const getReservationList = (context, type) => {
  const botId = router.currentRoute.value.query?.botId;
  const clientId = context.rootState.client?.clientId;
  const page = context.state?.reservationsListPage;
  const perpage = context.state?.perpage;
  return apiModules.MemberCentre.default.getReservationList({
    botId,
    clientId,
    type,
    page,
    perpage,
  }).then((result) => {
    const reservations = result.data.reservations.map((item) => ({ ...item, time: moment(item.timeStart).format('YYYY-MM-DD') }));
    context.commit(types.RESERVATIONS_LIST, reservations);
    context.commit(types.RESERVATIONS_LIST_PAGINATION, result.data.pagination);
    return result.data;
  }).catch((error) => error.response.data);
};

const getNextPageReservationsList = (context, type) => {
  const botId = router.currentRoute.value.query?.botId;
  const clientId = context.rootState.client?.clientId;
  const page = context.state.reservationsListPage + 1;
  const perpage = context.state?.perpage;
  context.commit(types.RESERVATIONS_LIST_PAGE, page);
  return apiModules.MemberCentre.default.getReservationList({
    botId,
    clientId,
    type,
    page,
    perpage,
  }).then((result) => {
    let reservations = [...context.state.reservationsList, ...result.data.reservations];
    reservations = filterDuplicates(reservations, 'id');
    context.commit(types.RESERVATIONS_LIST, reservations);
    context.commit(types.RESERVATIONS_LIST_PAGINATION, result.data.pagination);
    return result.data;
  }).catch((error) => error.response.data);
};

const getReservation = (_context, id) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.MemberCentre.default.getReservation({ botId, id }).then((result) => result.data).catch((error) => error.response.data);
};

const deleteReservation = (_context, id) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.MemberCentre.default.deleteReservation({ botId, id }).then((result) => result.data).catch((error) => error.response.data);
};

const initReservationsList = (context) => {
  context.commit(types.RESERVATIONS_LIST_PAGE, 1);
  context.commit(types.RESERVATIONS_LIST, []);
  context.commit(types.RESERVATIONS_LIST_PAGINATION, {
    totalPages: 0,
    currentPage: 0,
    hasPre: false,
    hasNext: false,
  });
};

const updateRewardCardStyle = (context, payload) => {
  context.commit(types.REWARD_CARD_STYLE, payload);
};

const getCoupons = (_context, {
  clientId,
  page,
  perpage,
  type,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.MemberCentre.default.getCoupons({
    botId,
    clientId,
    page,
    perpage,
    type,
  }).then((result) => result?.data)
    .catch((error) => error?.response?.data);
};

const cancelGetCouponsSource = () => {
  apiModules.MemberCentre.default.cancelGetCouponsSource();
};

const useCoupon = (_context, {
  clientId,
  id,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.MemberCentre.default.useCoupon({
    botId,
    clientId,
    id,
  }).then((result) => result.data)
    .catch((error) => error.response.data);
};

const getClientRecords = (_context, {
  clientId,
  page,
  perpage,
  type,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.MemberCentre.default.getClientRecords({
    botId,
    clientId,
    page,
    perpage,
    type,
  }).then((result) => result?.data)
    .catch((error) => error?.response?.data);
};

export {
  getQuestionnaireAnswersList,
  getNextPageQuestionnaireAnswersList,
  getQuestionnaireAnswer,
  getReservationList,
  getNextPageReservationsList,
  getReservation,
  deleteReservation,
  initReservationsList,
  updateRewardCardStyle,
  getCoupons,
  cancelGetCouponsSource,
  useCoupon,
  getClientRecords,
};
