export const FINISH_MESSAGE = 'FINISH_MESSAGE';
export const IS_LOADING = 'IS_LOADING';
// alert
export const DIALOG = 'DIALOG';
// client
export const CLIENT_ID = 'CLIENT_ID';
export const CLIENT_INFO = 'CLIENT_INFO';
// memberCentre
export const QUESTIONNAIRE_ANSWERS_LIST_PAGINATION = 'QUESTIONNAIRE_ANSWERS_LIST_PAGINATION';
export const QUESTIONNAIRE_ANSWERS_LIST_PAGE = 'QUESTIONNAIRE_ANSWERS_LIST_PAGE';
export const QUESTIONNAIRE_ANSWERS_LIST = 'QUESTIONNAIRE_ANSWERS_LIST';
export const RESERVATIONS_LIST_PAGINATION = 'RESERVATIONS_LIST_PAGINATION';
export const RESERVATIONS_LIST_PAGE = 'RESERVATIONS_LIST_PAGE';
export const RESERVATIONS_LIST = 'RESERVATIONS_LIST';
export const REWARD_CARD_STYLE = 'REWARD_CARD_STYLE';
// lottery
export const LOTTERY_GAME = 'LOTTERY_GAME';
// rewardCard
export const CURRENT_POINT = 'CURRENT_POINT';
export const GIFT_INFO = 'GIFT_INFO';
export const WINNING_RECORD_INFO_DATA = 'WINNING_RECORD_INFO_DATA';
