// state
const state = {
};

// mutations
const mutations = {
};

export {
  state,
  mutations,
};
