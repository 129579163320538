import apiModules from '@/api/index';
import router from '../../router';
import * as types from '../mutations_type';

const getRewardCard = (context, clientId) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.RewardCard.default.getRewardCard({
    botId,
    clientId,
  }).then((result) => {
    context.commit(types.CURRENT_POINT, result.data.currentPoint);
    return result.data;
  }).catch((error) => error.response.data);
};

const getRewardCardGift = (context, { id, clientId }) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.RewardCard.default.getRewardCardGift({
    id,
    botId,
    clientId,
  }).then((result) => {
    context.commit(types.GIFT_INFO, result.data.gift);
    return result.data;
  }).catch((error) => error.response.data);
};

const exchangeRewardCardGift = (_context, {
  clientId,
  name,
  birthday,
  phone,
  email,
  address,
  giftId,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.RewardCard.default.exchangeRewardCardGift({
    botId,
    clientId,
    name,
    birthday,
    phone,
    email,
    address,
    giftId,
  }).then((result) => result.data)
    .catch((error) => error.response.data);
};

const scanRewardCardPointQRCode = (context, {
  clientId,
  pointId,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.RewardCard.default.scanRewardCardPointQRCode({
    botId,
    clientId,
    pointId,
  }).then((result) => {
    context.commit(types.CURRENT_POINT, result.data.currentPoint);
    return result.data;
  }).catch((error) => error.response.data);
};

const updateWinningRecordInfoData = (context, payload) => {
  context.commit(types.WINNING_RECORD_INFO_DATA, payload);
};

export {
  getRewardCard,
  getRewardCardGift,
  exchangeRewardCardGift,
  scanRewardCardPointQRCode,
  updateWinningRecordInfoData,
};
