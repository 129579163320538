import apiModules from '@/api/index';

const getReserve = (_context, id) => (
  apiModules.Reserve.default.getReserve(id).then((result) => result.data)
);

const submitReservation = (_context, {
  mantaBotId,
  mantaTourManagementId,
  phone,
  timeStart,
  timeEnd,
  forms,
  clientId,
  orderUserName,
  platform,
}) => (
  apiModules.Reserve.default.submitReservation({
    mantaBotId,
    mantaTourManagementId,
    phone,
    timeStart,
    timeEnd,
    forms,
    clientId,
    orderUserName,
    platform,
  }).then((result) => result.data).catch((error) => error.response.data)
);

const getReserveFullLimitPeriod = (_context, { id, year, month }) => (
  apiModules.Reserve.default.getReserveFullLimitPeriod({ id, year, month }).then((result) => result.data)
);

export {
  getReserve,
  submitReservation,
  getReserveFullLimitPeriod,
};
