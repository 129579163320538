import { cosenRequest } from '../init';

function getClientId(botId, lineClientId) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/user',
      method: 'get',
    };
    cosenRequest(params, {
      params: {
        botId, clientId: lineClientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getClientId,
};
