import axios from 'axios';

const userRequest = axios.create({
  baseURL: `${process.env.VUE_APP_APIPATH}`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: process.env.NODE_ENV === 'development',
});
const request = (params, data) => userRequest[`${params.method}`](`${params.url}`, data, params.config);

userRequest.defaults.headers.common.Authorization = `Bearer ${process.env.VUE_APP_APITOKEN}`;

const cosen = axios.create({
  baseURL: `${process.env.VUE_APP_APIPATH}`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: process.env.NODE_ENV === 'development',
});

const cosenRequest = (params, data) => cosen[`${params.method}`](`${params.url}`, data, params.config);

cosen.defaults.headers.common.Authorization = `Bearer ${process.env.VUE_APP_COSEN_APITOKEN}`;

export {
  userRequest,
  request,
  cosenRequest,
};
