// eslint-disable-next-line import/prefer-default-export
export const questionnaireAnswersListPagination = (state) => state.questionnaireAnswersListPagination;
export const questionnaireAnswersListPage = (state) => state.questionnaireAnswersListPage;
export const questionnaireAnswersList = (state) => state.questionnaireAnswersList;
export const reservationsListPagination = (state) => state.reservationsListPagination;
export const reservationsListPage = (state) => state.reservationsListPage;
export const reservationsList = (state) => state.reservationsList;
const bgcList = [
  '#A5C8E6',
  '#F3BEB7',
  '#FBF4CD',
  '#A1D6AD',
  '#A3A8CA',
];
const colorList = [
  '#2E3567',
  '#9A1717',
  '#E08907',
  '#0B6E39',
  '#2E3567',
];
const descriptionSectionBgcList = [
  '#CCE1F433',
  '#FBD8D433',
  '#FFFAE033',
  '#D2F5DA33',
  '#CCE1F433',
];
export const backgroundColor = (state) => bgcList[state.rewardCardStyle % 5];
export const textColor = (state) => colorList[state.rewardCardStyle % 5];
export const descriptionBackgroundColor = (state) => descriptionSectionBgcList[state.rewardCardStyle % 5];
