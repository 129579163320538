import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/questionnaire',
        name: 'questionnaire',
        component: () => import('../views/Questionnaire.vue'),
      },
      {
        path: '/reserve',
        name: 'reserve',
        component: () => import('../views/Reserve.vue'),
      },
      {
        path: '/growthTool',
        name: 'growthTool',
        component: () => import('../views/GrowthTool.vue'),
      },
    ],
  },
  {
    path: '/finish',
    name: 'finish',
    component: () => import('../views/Finish.vue'),
  },
  {
    path: '/linePopupCallback',
    component: () => import('../views/Popupcallback.vue'),
  },
  {
    path: '/cosen/login/redirect',
    name: 'cosenLogin',
    component: () => import('../views/CosenLogin.vue'),
  },
  {
    path: '/memberCentre',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '',
        name: 'memberCentre',
        component: () => import('../views/memberCentre/MemberCentre.vue'),
      },
      {
        path: 'questionnaire',
        children: [
          {
            path: '',
            name: 'memberCentreQuestionnaire',
            component: () => import('../views/memberCentre/questionnaire/Questionnaire.vue'),
          },
          {
            path: ':questionnaireId',
            name: 'memberCentreQuestionnaireInfo',
            component: () => import('../views/memberCentre/questionnaire/QuestionnaireInfo.vue'),
          },
        ],
      },
      {
        path: 'reserve',
        children: [
          {
            path: '',
            name: 'memberCentreReserve',
            component: () => import('../views/memberCentre/reserve/Reserve.vue'),
          },
          {
            path: ':reserveId',
            name: 'memberCentreReserveInfo',
            component: () => import('../views/memberCentre/reserve/ReserveInfo.vue'),
          },
        ],
      },
      {
        path: 'rebind',
        name: 'memberCentreRebind',
        component: () => import('../views/memberCentre/Rebind.vue'),
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import('../views/memberCentre/Coupon.vue'),
      },
      {
        path: 'rewardCard',
        name: 'rewardCard',
        component: () => import('../views/memberCentre/RewardCard.vue'),
      },
      {
        path: 'giftInfo/:giftId',
        name: 'giftInfo',
        component: () => import('../views/memberCentre/gift/GiftInfo.vue'),
      },
      {
        path: 'giftInfo/:giftId/receiveGift',
        name: 'receiveGift',
        component: () => import('../views/memberCentre/gift/ReceiveGiftForm.vue'),
      },
      {
        path: 'roulette',
        name: 'roulette',
        component: () => import('../views/memberCentre/Roulette.vue'),
      },
      {
        path: 'roulette/activityDescription',
        name: 'rouletteActivityDescription',
        component: () => import('../views/memberCentre/ActivityDescription.vue'),
      },
      {
        path: 'giftList',
        name: 'giftList',
        component: () => import('../views/memberCentre/gift/GiftList.vue'),
      },
      {
        path: 'winningRecord',
        name: 'winningRecord',
        component: () => import('../views/memberCentre/WinningRecord.vue'),
      },
      {
        path: 'winningRecord/:id',
        name: 'winningRecordInfo',
        component: () => import('../views/memberCentre/winningRecord/WinningRecordInfo.vue'),
      },
    ],
    meta: { parent: 'memberCentre' },
  },
  {
    path: '/shareMessage',
    name: 'shareMessage',
    component: () => import('../views/ShareMessage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
