import { createApp } from 'vue';
import VCalendar from 'v-calendar';
import { defineRule } from 'vee-validate';
import * as rules from '@vee-validate/rules';
import customRules from '@/helpers/customRules';
import App from './App.vue';
import router from './router';
import store from './store';
import 'v-calendar/dist/style.css';

// Install all rules
Object.keys(rules).forEach((rule) => {
  defineRule(rule, rules[rule]);
});

Object.keys(customRules).forEach((rule) => {
  defineRule(rule, customRules[rule]);
});

createApp(App)
  .use(store)
  .use(router)
  .use(VCalendar, {})
  .mount('#app');
