import apiModules from '@/api/index';

const getQuestionnaire = (_context, id) => (
  apiModules.Questionnaire.default.getQuestionnaire(id).then((result) => result.data)
);

const submitQuestionnaireAnswer = (_context, {
  botId,
  questionnaireId,
  clientId,
  answers,
}) => (
  apiModules.Questionnaire.default.submitQuestionnaireAnswer({
    botId,
    questionnaireId,
    clientId,
    answers,
  }).then((result) => result.data)
);

const getQuestionnairePersonalData = (_context, { botId, clientId }) => (
  apiModules.Questionnaire.default.getQuestionnairePersonalData({ botId, clientId }).then((result) => result.data)
);

export {
  getQuestionnaire,
  submitQuestionnaireAnswer,
  getQuestionnairePersonalData,
};
