import axios from 'axios';
import { request } from '../init';

function getLotteryGame({
  botId,
  clientId,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/lottery/game',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getLotteryGameGifts({
  botId,
  clientId,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/lottery/gifts',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

let getLotteryGameClientGiftsSource = axios.CancelToken.source();

function getLotteryGameClientGifts({
  botId,
  clientId,
  perpage,
  page,
  type,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/lottery/client/gifts',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        clientId,
        perpage,
        page,
        type,
      },
      cancelToken: getLotteryGameClientGiftsSource.token,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function cancelGetLotteryGameClientGifts() {
  getLotteryGameClientGiftsSource.cancel();
  getLotteryGameClientGiftsSource = axios.CancelToken.source();
}

function submitClientInfoToLotteryGift({
  id,
  botId,
  clientId,
  name,
  birthday,
  phone,
  email,
  address,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: `/liff/lottery/client/gift/${id}`,
      method: 'patch',
      config: { params: { botId, clientId } },
    };
    request(params, {
      name,
      birthday,
      phone,
      email,
      address,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function playLotteryWheel({
  botId,
  clientId,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/lottery/wheel',
      method: 'post',
      config: { params: { botId, clientId } },
    };
    request(params).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getLotteryGame,
  getLotteryGameGifts,
  getLotteryGameClientGifts,
  cancelGetLotteryGameClientGifts,
  submitClientInfoToLotteryGift,
  playLotteryWheel,
};
